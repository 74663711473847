import React from "react";
import SectionText from "../SectionText";
import SectionTitle from "../SectionTitle";
import { Post } from "../../pages/post/api/useGetPostsApi";
interface CardProps {
    item: Post;
}

const Card:React.FC<CardProps> = ({item}) => {
    return (
        <div data-testid={item.id.toString()} className="bg-mainBG p-4 rounded-md cursor-pointer hover:drop-shadow-lg ease-in duration-200 listItem">
            <SectionTitle  title={item.id+") "+item.title} />
            <SectionText text={item.body}/>
        </div>
    )
}

export default Card;