import React from "react";

interface SectionTextProps {
    text: string;
}

const SectionText:React.FC<SectionTextProps> = ({text}) => {
    return (
        <h2 className="text-secondary text-md">{text}</h2>
    )
}

export default SectionText;