import React from "react";

interface SectionTitleProps {
    title: string;
}

const SectionTitle:React.FC<SectionTitleProps> = ({title}) => {
    return (
        <h2 className="text-primary text-lg font-semibold">{title}</h2>
    )
}

export default SectionTitle;