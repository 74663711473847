import React, { ReactNode } from "react";

interface LayoutProps {
    children: ReactNode;
}

const Layout:React.FC<LayoutProps> =({children})=> {
    return (
        <div className="container mx-auto h-full ">
            <div id="main" className="flex flex-col gap-2 h-auto py-4">
                {children}
            </div>
        </div>
    )
}

export default Layout;