import { NavLink } from "react-router-dom";
import Layout from "../../components/Layout";
import Title from "../../components/Title";
import SectionTitle from "../../components/SectionTitle";
import { CAPITAL_LETTERS, NUMBERS, SMALL_LETTERS, SPECIAL_CHARACTERS } from "../../constants/Constants";

const Password = () => {
    
    // Generate a random number between min and max (inclusive)
    function getRandomNumber(min:number, max:number) {
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }

    function getRandomCharacter(str:string) {
        const randomIndex = Math.floor(Math.random() * str.length);
        return str.charAt(randomIndex);
    }

    function generateRandomPassword() {
        let password = "";
        // Flags to track if we have selected characters from each category
        let hasCapital = false;
        let hasSmall = false;
        let hasNumber = false;
        let hasSpecial = false;
    
        while (!(hasCapital && hasSmall && hasNumber && hasSpecial)) {
            // Get a random character from each category
            if (!hasCapital) {
                password += getRandomCharacter(CAPITAL_LETTERS);
                hasCapital = true;
            }
            if (!hasSmall) {
                password += getRandomCharacter(SMALL_LETTERS);
                hasSmall = true;
            }
            if (!hasNumber) {
                password += getRandomCharacter(NUMBERS);
                hasNumber = true;
            }
            if (!hasSpecial) {
                password += getRandomCharacter(SPECIAL_CHARACTERS);
                hasSpecial = true;
            }
    
            // Append additional random characters if needed
            while (password.length < 8) {
                const category = getRandomNumber(0, 3); // 0: Capital, 1: Small, 2: Number, 3: Special
                switch (category) {
                    case 0:
                        password += getRandomCharacter(CAPITAL_LETTERS);
                        hasCapital = true;
                        break;
                    case 1:
                        password += getRandomCharacter(SMALL_LETTERS);
                        hasSmall = true;
                        break;
                    case 2:
                        password += getRandomCharacter(NUMBERS);
                        hasNumber = true;
                        break;
                    case 3:
                        password += getRandomCharacter(SPECIAL_CHARACTERS);
                        hasSpecial = true;
                        break;
                }
            }
        }
        return password;
    }    
    
    return (
    <Layout>
        <div className="flex flex-row gap-4 items-center">
            <NavLink to={"/"}>
                <SectionTitle title="Posts" />
            </NavLink>
            <Title title="Password" />
        </div>
        <h2 data-testid="password">
            {generateRandomPassword()}
        </h2>
    </Layout>
    )
}

export default Password;