import { useEffect, useMemo, useState } from "react";
import Layout from "../../../components/Layout";
import Shimmer from "../../../components/Shimmer";
import Title from "../../../components/Title";
import Card from "../../../components/card/Card";
import { Post, PostsApiReturn, useGetPostsApi } from "../api/useGetPostsApi";
import { toast } from 'react-toastify';
import { FaArrowDown19,FaArrowUp19 } from "react-icons/fa6";
import SectionTitle from "../../../components/SectionTitle";
import { NavLink } from "react-router-dom";



const PostList = () => {

    const { data, refetch:fetch, isSuccess,isLoading } = useGetPostsApi() as PostsApiReturn;
    const [paginatedData,setPaginatedData] = useState<Post[]>([]);
    const [page,setPage] = useState<number>(1);
    const [innerHeight, setInnerHeight] = useState<number>(window.innerHeight);
    const [isResizing, setIsResizing] = useState<boolean>(false);
    const [isLoadingPg, setIsLoadingPg] = useState<boolean>(false);
    // Define a variable for the percentage of page height to trigger pagination
    const threshold = 0.95; // 95% of the page height
    const [isAsc,setIsAsc] = useState<boolean>(true);
    const [sortedData,setSortedData] = useState<Post[]>([]);
    
    useEffect(()=>{
        fetch();

        const handleResize = () => {
            setInnerHeight(window.innerHeight);
            setIsResizing(true)
        };

        window.addEventListener('resize', handleResize);

        const debouncedHandleScroll = debounce(handleScroll, 100)
      
        window.addEventListener('scroll', debouncedHandleScroll);
      
          return () => {
            window.removeEventListener('scroll', debouncedHandleScroll);
            window.removeEventListener('resize', handleResize);
          }
    },[])

    const sortedAndSlicedData = useMemo(() => {
        setPaginatedData([]);
        setPage(1);
        if(data){
            if (isAsc) {
                return data;
            } else {
                return data.slice().sort((a, b) => b.id - a.id);
            }
        }
    }, [data, isAsc]);


    useEffect(()=>{
        
        if(sortedAndSlicedData){
            const startIndex = (page - 1) * 10;
            const endIndex = startIndex + 10;

            if(startIndex > sortedAndSlicedData.length )
            {
                toast.error("You have reached end!", {
                    position: "bottom-center",})
                return
            }
            if(!isResizing){
                let newData:Post[];
                newData = sortedAndSlicedData.slice(startIndex, endIndex);

                // delay is just to simulate api call
                setIsLoadingPg(true)
                setTimeout(() => {
                    setIsLoadingPg(false);
                    setPaginatedData(prev => [...prev, ...newData]);
                    
                }, 1000);
                

                setIsResizing(false);
            }
            
            if((innerHeight > 84 * endIndex)){
                // time out just to simulate api call
                setTimeout(()=>{
                    setIsResizing(false);
                    setPage(prevPage => prevPage + 1);
                },1000)
            }else{
                setIsResizing(false);
            }
        }
    },[sortedAndSlicedData,page,innerHeight])  

    // pagination starts here
    function handleScroll() {
        // Calculate the scroll position
        const scrollPosition = window.innerHeight + window.pageYOffset;
        
        // Calculate the threshold position
        const thresholdPosition = document.documentElement.offsetHeight * threshold;
        

        if (scrollPosition >= thresholdPosition) {
            // go to next page
            setPage(prevPage => prevPage + 1);
        }
    }

    function handleSearch(text:string){
        setPage(1);
        if(text){
            const filteredData = data?.filter(item =>
                item.title.toLowerCase().includes(text.toLowerCase())
            ).slice(0, 10);
            if(filteredData){
                setPaginatedData(filteredData);
            }
        }else{
            const startIndex = (page - 1) * 10;
            const endIndex = startIndex + 10;

            let newData:Post[];
            if(sortedAndSlicedData){
                newData = sortedAndSlicedData.slice(startIndex, endIndex);
    
                    // delay is just to simulate api call
                    setIsLoadingPg(true)
                    setTimeout(() => {
                        setIsLoadingPg(false);
                        setPaginatedData(newData);
                    }, 1000);
            }
        }
    }

    return (
        <Layout>
            <div className="flex flex-row justify-between">
                <div className="flex flex-row gap-4 items-center">
                    <Title title="Posts" />
                    <NavLink to={"/password"}>
                        <SectionTitle title="Password" />
                    </NavLink>
                </div>

                <div className="flex flex-row items-center gap-4 cursor-pointer">
                    <input 
                        onChange={(e)=>handleSearch(e.target.value)}
                        placeholder="Search" 
                        type="text" 
                        className="border-solid border-2 w-[250px] p-2"/>
                        {
                            isAsc ?
                            <FaArrowDown19 onClick={()=>setIsAsc(false)} style={{fontSize:"25px", color:"var(--primary-color)"}} />
                            : 
                            <FaArrowUp19 onClick={()=>setIsAsc(true)} style={{fontSize:"25px", color:"var(--primary-color)"}} />
                        }
                </div>
            </div>
                {
                    isLoading && 
                    <Shimmer num={5} />
                }
                {
                    paginatedData?.map((item:Post)=>(
                            <Card key={item.id}  item={item} />
                        ))
                }
                {
                    isLoadingPg &&
                    <div data-testid="loading">
                        <Shimmer />
                    </div>
                }
                <button data-testid="loadmore" onClick={()=>setPage(page+1)} className="hidden">LoadMore</button>
        </Layout>
    )
}

// Debounce function
function debounce<T extends (...args: any[]) => any>(func: T, delay: number): (...args: Parameters<T>) => void {
    let timeoutId: NodeJS.Timeout;
    return function(this: any, ...args: Parameters<T>) {
        clearTimeout(timeoutId);
        
        timeoutId = setTimeout(() => {
            func.apply(this, args);
        }, delay);
    };
}

export default PostList;