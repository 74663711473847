import React from "react";

interface ShimmerProps {
    num?:number
}
const Shimmer:React.FC<ShimmerProps> = ({num}) => {

    const duplicatesArray = new Array(num?num:1).fill(null);

    return (
        <>
            {
                duplicatesArray.map((_, index) => (
                    <div key={index} role="status" className="w-full animate-pulse bg-mainBG p-4 rounded-md">
                        <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-700 w-48 mb-4"></div>
                        <div className="h-2 bg-gray-300 rounded-full dark:bg-gray-700 mb-2.5"></div>
                        <div className="h-2 bg-gray-300 rounded-full dark:bg-gray-700 mb-2.5"></div>
                        <div className="h-2 bg-gray-300 rounded-full dark:bg-gray-700 max-w-[460px] mb-2.5"></div>
                    </div>
                ))
            }
        </>
    )
}

export default Shimmer;