import { QueryObserverResult, RefetchOptions, RefetchQueryFilters, useQuery } from "react-query";
import { axiosNoAuth } from "../../../axios/axiosNoAuth"
import { AxiosResponse } from "axios";

export interface Post {
    userId: number;
    id: number;
    title: string;
    body: string;
  }


export interface PostsApiReturn {
    data?: Post[];
    refetch: <TPageData>(options?: RefetchOptions & RefetchQueryFilters<TPageData>) => Promise<QueryObserverResult<Post[] | undefined, any>>;
    isSuccess:boolean;
    isLoading:boolean;
  }

export const useGetPostsApi = ():PostsApiReturn | undefined => {
    // making async api call
    async function getPosts():Promise<Post[] | undefined>{
        try {
            // Simulate delay using setTimeout
            await new Promise(resolve => setTimeout(resolve, 1000));
            
            const res:AxiosResponse<Post[]> = await axiosNoAuth.get(
                "/posts"
            );
            return res.data;
        }catch(error) {
            throw error;
        }
    }

    // using use query to get status and helper functions
    const {data,refetch,isSuccess,isLoading} = useQuery(
        "getPosts",getPosts,{enabled: false}
    )

    return {
        data,
        refetch,
        isSuccess,
        isLoading
    };
}